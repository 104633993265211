import { FC } from 'react'
import '../../../components/App/Checkout/Auth/Auth.scss'
import Checkout from '../../../components/App/Checkout';

const CheckoutPage: FC = () => {
    return (
        <div className='w-screen h-screen overflow-auto'>
            <Checkout />
        </div>
    )
}

export default CheckoutPage